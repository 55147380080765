import styled from "@emotion/styled"
import React, { useEffect, useRef } from "react"

import { useScrollController } from "./scroll-controller"
import { Block, TextBlock, BlockProps } from "./story-components"

const PinBlock = styled(Block)`
  height: 100vh;
`

export const RevealBlock = styled(TextBlock)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  margin: 0;
  opacity: 0;

  transition: opacity 500ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &.visible {
    opacity: 1;
  }
`

interface Props extends BlockProps {
  children: React.ReactNode
}

const ScrollPinReveal = ({ children, ...props }: Props) => {
  const trigger = useRef<any>()

  const duration = 3000

  const { ScrollMagic, controller } = useScrollController()

  useEffect(() => {
    if (ScrollMagic && controller) {
      const children = [...trigger.current.children] as HTMLElement[]
      const offset = duration / children.length
      // pin effect
      new ScrollMagic.Scene({
        triggerElement: trigger.current,
        duration,
        triggerHook: "onLeave",
      })
        .setPin(trigger.current)
        .addTo(controller)

      // reveal toggles
      children.forEach((childElement: HTMLElement, index: number) => {
        new ScrollMagic.Scene({
          triggerElement: trigger.current,
          offset: offset * index,
          duration: offset,
          triggerHook: "onLeave",
        })
          .setClassToggle(childElement, "visible")
          .addTo(controller)
      })
    }
  }, [ScrollMagic, controller])

  return (
    <PinBlock {...props} ref={trigger}>
      {children}
    </PinBlock>
  )
}

export default ScrollPinReveal
