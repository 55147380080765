import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"
import ScrollPinReveal, { RevealBlock } from "@components/scroll-pin-reveal"
import Scrollshow from "@components/scrollshow"
import {
  Block,
  CenterCite,
  CenterText,
  Figure,
  Intro,
  IntroImage,
  NavLink,
  NavLinks,
  ParallaxBlock,
  StoryHeading,
  StoryHeadingBg,
  StoryMeta,
  StoryTitle,
  TextBlock,
  VideoQuote,
} from "@components/story-components"
import StoryPicker from "@components/storypicker"
import Video from "@components/video"
import YoutubeVideo from "@components/YoutubeVideo"
import { graphql } from "gatsby"
import React from "react"
import { FluidImg } from "src/types"

import rahmaMp4 from "../../assets/rahma.mp4"
import rahmaWebM from "../../assets/rahma.webm"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftIcon from "../../images/chevron-left.inline.svg"
import RightIcon from "../../images/chevron-right.inline.svg"

type Props = {
  data: {
    rahma: FluidImg
    ambulance: FluidImg
    syria: FluidImg
    flag: FluidImg
    farahFebri: FluidImg
    farah: FluidImg
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="Rahma's story" />
      <Block>
        <Video
          sources={[
            { src: rahmaWebM, type: "video/webm" },
            { src: rahmaMp4, type: "video/mp4" },
          ]}
        />
        <VideoQuote>
          <CenterCite maxWidth="63rem" color="wheat">
            Rahma's Story
            <StoryMeta>Indonesia</StoryMeta>
          </CenterCite>
        </VideoQuote>
        <StoryHeading>
          <StoryHeadingBg>
            <NavLinks>
              <NavLink to="/story/odin">
                <LeftIcon height="1em" strokeWidth="2px" /> Previous
              </NavLink>
              <NavLink to="/story/ahmad">
                Next story <RightIcon height="1em" strokeWidth="2px" />
              </NavLink>
            </NavLinks>
            <StoryTitle>
              ‘Around the time we joined the caliphate, we had been at the
              lowest point of our lives’
            </StoryTitle>
          </StoryHeadingBg>
        </StoryHeading>
      </Block>
      <Intro>
        <IntroImage fluid={data.rahma.childImageSharp.fluid} />
        <CenterText fontSize={["xl", "2xl"]}>
          My name is Rahma. I am 34 years old.
          <br /> I have three children, two of them with autism. I was also ill
          when my husband’s company went bankrupt, and it seemed like we had no
          economic solution to support our healthcare whatsoever.
        </CenterText>
      </Intro>
      <OpacityParallax
        bg="wheat"
        threshold={0.5}
        backgroundProps={{
          fluid: data.ambulance.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="cyprus">
            <CenterText fontSize={["xl", "2xl"]}>
              After I saw the caliphate declaration online, I become hooked.
              Someone who was already in Syria told me how the health facilities
              there were incredible: all free of charge and highly professional.
              My husband and I, we picked up loans, sold our house and
              everything we had, gathered up our family and left.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <Scrollshow
        fluidImages={[
          data.farahFebri.childImageSharp.fluid,
          data.farah.childImageSharp.fluid,
        ]}
      />
      <ParallaxSticky>
        <TextBlock color="cyprus">
          <CenterText>
            A total of 26 members of my family including my mother flew to
            Turkey and then in the dead of night, we crossed over the border to
            Raqqa with the help of a smuggler. I was carrying with me a baby, my
            one-year-old child.
          </CenterText>
        </TextBlock>
      </ParallaxSticky>
      <ScrollPinReveal bg="cyprus">
        <RevealBlock color="wheat">
          <CenterText fontSize={["xl", "2xl"]}>
            When we arrived, I was still very sick. A doctor at Raqqa examined
            me and told me I had to go to a hospital in Mosul, Iraq. It took
            about 8 hours for me to get there by road. I have never in my life
            seen such a hospital - no-one trying to keep the hospital clean and
            there was human waste was scattered everywhere in the bathrooms.
          </CenterText>
        </RevealBlock>
        <RevealBlock color="wheat">
          <CenterCite maxWidth="100%">
            <CenterCite>‘I was in shock when I saw the reality.’</CenterCite>
          </CenterCite>
        </RevealBlock>
      </ScrollPinReveal>
      <OpacityParallax
        bg="wine"
        color="wheat"
        threshold={0.5}
        backgroundProps={{
          fluid: data.syria.childImageSharp.fluid,
          loading: "eager",
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText maxWidth="100%">
              <CenterCite>
                ‘And There was another thing that still makes me shudder.’
              </CenterCite>
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <TextBlock bg="wine" color="wheat">
        <CenterText fontSize={["xl", "2xl"]}>
          My neighbor in Raqqa was a Syrian girl, only 15 years old. Her child
          was one year old. I asked her, how come you are married at this young
          age. She said “My parents wanted me to marry an ISIS fighter, but I
          want to play and to go to school. I have to take care of my child
          now.” I was sad hearing that.
        </CenterText>
        <CenterText fontSize={["xl", "2xl"]}>
          Women under ISIS are meant to be a ‘baby factory’. They go after girls
          who have only just had their period for first time. They want to make
          many children and then teach them to be terrorists. There are many
          women who were passed from fighter to fighter, stuck in a cycle like a
          rotating trophy. That disgusted me. I came to the conclusion that ISIS
          was really not Islam.
        </CenterText>
      </TextBlock>
      <ParallaxBlock
        scaleFactor={1.6}
        backgroundProps={{
          fluid: data.flag.childImageSharp.fluid,
          loading: "eager",
        }}
      />

      <TextBlock p="" py="5xl" bg="cyprus">
        <Figure>
          <YoutubeVideo videoId="oENspCMiMqg" />
        </Figure>
      </TextBlock>

      <StoryPicker />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    rahma: file(relativePath: { eq: "rahma.jpg" }) {
      ...BlockImage
    }
    ambulance: file(relativePath: { eq: "rahma/jakarta_ambulance.jpg" }) {
      ...BlockImage
    }
    syria: file(relativePath: { eq: "rahma/syria.jpg" }) {
      ...BlockImage
    }
    flag: file(relativePath: { eq: "rahma/black_flag.jpg" }) {
      ...BlockImage
    }
    farahFebri: file(relativePath: { eq: "rahma/farah_febri.jpg" }) {
      ...BlockImage
    }
    farah: file(relativePath: { eq: "rahma/farah.jpg" }) {
      ...BlockImage
    }
  }
`

export default IndexPage
