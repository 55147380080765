import React, { useEffect, useRef } from "react"
import { TextBlock, Figure } from "./story-components"
import styled from "@emotion/styled"
import Image, { FluidObject } from "gatsby-image"
import { useScrollController } from "./scroll-controller"

const FigImage = styled.div`
  position: relative;
  overflow: hidden;
`

const AspectRatio = styled.div`
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%; /* 16:9 */
`

const SlideShowImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  transition: opacity 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;

  &.visible {
    opacity: 1;
  }
`

interface ScrollshowProps {
  fluidImages: FluidObject[]
  duration?: number
}

const Scrollshow = ({ fluidImages, duration = 1500 }: ScrollshowProps) => {
  const trigger = useRef<any>()
  const slideshowRef = useRef<HTMLDivElement | null>(null)

  const offset = duration / fluidImages.length

  const { ScrollMagic, controller } = useScrollController()

  useEffect(() => {
    if (ScrollMagic && controller) {
      new ScrollMagic.Scene({
        triggerElement: trigger.current,
        duration,
        triggerHook: "onLeave",
      })
        .setPin(trigger.current)
        .addTo(controller)

      const slides = slideshowRef.current!.querySelectorAll<HTMLDivElement>(
        ".slide"
      )

      slides.forEach((slide, index: number) => {
        if (index === 0) {
          // first image should always be visible when scrolling down
          slide.style.opacity = "1"

          return
        }

        new ScrollMagic.Scene({
          triggerElement: trigger.current,
          offset: index * offset,
          triggerHook: "onLeave",
        })
          .setClassToggle(slide, "visible")
          .addTo(controller)
      })
    }
  }, [ScrollMagic, controller, duration, offset])

  return (
    <TextBlock height="100vh" color="cyprus" ref={trigger}>
      <Figure>
        <FigImage ref={slideshowRef}>
          <AspectRatio />
          {fluidImages.map((image: any, index: number) => (
            <SlideShowImage className="slide" fluid={image} key={index} />
          ))}
        </FigImage>
      </Figure>
    </TextBlock>
  )
}

export default Scrollshow
